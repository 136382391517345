'use client'

import { forwardRef, useRef, useState, MouseEvent, useCallback, ReactElement, ReactNode } from 'react'
import { cls, whisper } from '@/utils'
import IconLoading from '@/public/assets/loading.svg'
import { Button as BaseButton, ButtonProps as BaseButtonProps } from '@/components/ui/button'
import { NO_OUTLINE_STYLE } from '@/constants'
import Tooltip, { TooltipProps } from '../tooltip'
import useWaitTime from '@/hooks/useWaitTime'

export interface ButtonProps extends BaseButtonProps {
  loading?: boolean
  loadingClassName?: string
  cooldown?: Date | null
  cooldownTooltip?: ReactNode | string
  tooltip?: ReactNode | string
  tooltipProps?: TooltipProps
  showTooltipWhenDisabled?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loading,
      children,
      onClick,
      disabled,
      cooldown,
      cooldownTooltip,
      tooltip,
      showTooltipWhenDisabled,
      tooltipProps,
      onMouseEnter,
      onMouseLeave,
      loadingClassName,
      ...props
    },
    ref,
  ) => {
    const waitTime = useWaitTime(cooldown)
    const isCountingDown = !!waitTime

    const popoverTimerRef = useRef<any>(null)

    const handleClick = useCallback(
      async (e: MouseEvent<HTMLButtonElement>) => {
        try {
          await onClick?.(e)
        } catch (error) {
          console.error(`onClick error: ${error}`)
        }
      },
      [onClick],
    )

    const realTooltip = isCountingDown ? cooldownTooltip : tooltip

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleMouseEnter = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (popoverTimerRef.current) {
          clearTimeout(popoverTimerRef.current)
        }
        popoverTimerRef.current = setTimeout(() => {
          setTooltipOpen(true)
        }, 300)

        onMouseEnter?.(e)
      },
      [onMouseEnter],
    )

    const handleMouseLeave = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (popoverTimerRef.current) {
          clearTimeout(popoverTimerRef.current)
        }
        popoverTimerRef.current = setTimeout(() => {
          setTooltipOpen(false)
        }, 300)
        onMouseLeave?.(e)
      },
      [onMouseLeave],
    )

    const button = (
      <BaseButton
        {...props}
        ref={ref}
        disabled={disabled || isCountingDown}
        className={cls(
          'gap-2 relative focus-visible:ring-0 focus-visible:ring-offset-0 duration-75',
          'min-w-max',
          NO_OUTLINE_STYLE,
          className,
        )}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {loading ? (
          <div className='flex min-w-max'>
            <div className='absolute size-full flex justify-center items-center box-border p-2 inset-0'>
              <IconLoading
                className={cls(
                  'animate-spin max-w-full max-h-full text-text-interactive',
                  props.variant === 'primary' ? 'text-text-on-color' : '',
                  loadingClassName,
                )}
              />
            </div>
            <div className={cls('min-w-max', 'invisible')}>{children}</div>
          </div>
        ) : isCountingDown ? (
          <span>Wait {waitTime}s</span>
        ) : (
          children
        )}
      </BaseButton>
    )

    if (realTooltip) {
      return (
        <Tooltip
          trigger={button}
          align='center'
          open={(showTooltipWhenDisabled && (disabled || isCountingDown)) || tooltipOpen}
          className={cls('', isCountingDown ? 'w-max max-w-56' : '')}
          {...tooltipProps}
        >
          {typeof realTooltip === 'string' ? <span className='text-text-on-color'>{realTooltip}</span> : realTooltip}
        </Tooltip>
      )
    }
    return button
  },
)

Button.displayName = BaseButton.displayName

export default Button
