import { currentTimeAtom } from '@/atoms'
import dayjs, { Dayjs } from 'dayjs'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

export default function useWaitTime(coolDown: Date | Dayjs | string | null | undefined) {
  const currentTime = useAtomValue(currentTimeAtom)

  const endTime = useMemo(() => (coolDown ? dayjs(coolDown) : null), [coolDown])

  const result = useMemo(() => {
    if (!endTime || !endTime.isValid() || endTime.isBefore(currentTime)) {
      return 0
    }

    return Math.max(0, endTime.diff(currentTime, 'second'))
  }, [currentTime, endTime])

  return result
}
